import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://www.cascadeo.com/wp-content/uploads/2022/11/KRgw2UkV_400x400.jpg" className="App-logo" alt="logo" />
        <p>
          <code>CI/CD on Amazon Web Services</code>
        </p>
        <p className="blue-green">
          Demo
        </p>
      </header>
    </div>
  );
}

export default App;
